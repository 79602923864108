import {
  Auth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  User,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  Firestore,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import { FirebaseApp } from "firebase/app";
import { User as StoreUser } from "@state/types";
import { getAnalytics } from "firebase/analytics";
import { getCookieValue, isBrowser, setCookie } from "@util/helper";
import { toast } from "react-toastify";
import { useStore } from "@state/store";

import { useTabVisibility } from "@util/hooks";
import { navigate } from "gatsby";

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
};

let app: FirebaseApp | undefined;
let auth: Auth;
let database: Firestore;
let analytics: any;

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const initFirebase = async () => {
  if (!isBrowser()) return null;
  const { initializeApp } = await import("firebase/app");
  const { getFirestore } = await import("firebase/firestore");
  const { getAuth } = await import("firebase/auth");

  app = app || initializeApp(firebaseConfig);
  auth = getAuth(app);
  database = getFirestore(app);
  analytics = getAnalytics(app);

  return { auth, database, analytics };
};

interface NewUser extends User {
  firstName?: string;
  lastName?: string;
  parentName?: string;
  parentEmail?: string;
  mobile?: string;
  license_key?: string;
  dob?: string;
  isGroupAdmin?: boolean;
}

/**
 *  Create or update user in firestore
 * @param data User auth data
 */
const setUserToDatabase = async (data: NewUser) => {
  if (data == null) return;

  //Add cookie
  setCookie("101LaunchpadLogin", "true", 1);

  const { uid, metadata } = data;

  const objectForFirebase = {
    displayName: data.displayName,
    uid: data.uid,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    parentName: data.parentName ?? "",
    parentEmail: data.parentEmail ?? "",
    mobile: data.mobile ?? "",
    createdAt: new Date(),
    license_key: data.license_key ?? "",
    dob: data.dob ?? "",
    isGroupAdmin: data.isGroupAdmin ?? false,
  };

  const docRef = doc(database, "users", uid);
  const docSnapshot = await getDoc(docRef);

  if (docSnapshot.exists()) {
    const userData = docSnapshot.data() as StoreUser;
    useStore.setState({
      user: { ...userData, uid },
      completed: userData?.completed,
      textInputs: userData?.textInputs,
      goals: userData?.goals,
      engagement: userData?.engagement,
    });
  } else {
    //Create a new user
    try {
      await setDoc(docRef, { ...objectForFirebase }, { merge: true });
      useStore.setState({ user: { ...objectForFirebase } });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    //Update activation keys
  }
};

/**
 * Sign in with google oauth
 */
const handleGoogleAuth = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    if (result == null || result?.user == null) {
      toast.error("An error occurred while trying to login");
      return;
    }

    console.log({ result });

    await setUserToDatabase(result?.user);
  } catch (err: any) {
    console.log(err);
    toast.error("Login Error: please check that your details are correct");
  }
};

/**
 *  Sign in with facebook oauth
 */

const handleFacebookAuth = async () => {
  try {
    const result = await signInWithPopup(auth, facebookProvider);
    if (result == null || result?.user == null) {
      toast.error("An error occurred while trying to login");
      return;
    }
    await setUserToDatabase(result?.user);
  } catch (err: any) {
    console.log(err);
    toast.error("Login Error: please check that your details are correct");
  }
};

/*
 * Check Activation code against license key
 */
const handleCheckActivationCode = async (code: string) => {
  try {
    const codeQuery = database
      ? query(collection(database, "license_keys"), where("key", "==", code))
      : undefined;

    let keys = [];

    if (codeQuery) {
      const adminSnapshot = await getDocs(codeQuery);
      adminSnapshot.docs.map(doc => {
        keys.push({ ...doc.data(), usersLoaded: false });
      });

      console.log({ keys });
      const matchingKey = keys[0];
      console.log({ matchingKey });

      if (matchingKey == null) return;
      if (matchingKey.assigned < matchingKey.usesAvailable) {
        console.log("key is valid");
        return matchingKey;
      }

      return null;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

const handleCheckGroupAdmin = async (code: string, email: string) => {
  try {
    const docRef = doc(database, "groups", code);
    const docSnapshot = await getDoc(docRef);
    const groupData = (await docSnapshot.data()) as any;
    if (groupData?.admin == email) {
      return true;
    }
    if (groupData?.groupMembers?.includes(email)) {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }

  return false;
};

/**
 * Create account with Email
 */
const handleCreateAccountWithEmail = async (data: any) => {
  const {
    email,
    password,
    firstName,
    lastName,
    mobile,
    parentName,
    parentEmail,
    license_key,
    dob,
    isGroupAdmin,
  } = data;

  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);

    if (result == null || result?.user == null) {
      toast.error("An error occurred while trying to login");
      return;
    }
    const toFirebase = {
      ...result.user,
      firstName,
      lastName,
      mobile,
      parentName,
      parentEmail,
      license_key,
      dob,
      isGroupAdmin,
    };

    console.log({ toFirebase });

    await setUserToDatabase(toFirebase);
    return result;
  } catch (err: any) {
    console.log(err);
    toast.error("Login Error: " + err.message);
    return err;
  }
};

/**
 * Login with Email
 */
const handleLoginWithEmail = async (email: string, password: string) => {
  console.log({ email, password });

  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    console.log({ result });

    if (result == null || result?.user == null) {
      toast.error("An error occurred while trying to login");
      return result;
    }

    await setUserToDatabase(result.user);
    return result;
  } catch (err: any) {
    console.log({ err });
    toast.error("Login Error: " + err.message);
    return err;
  }
};

/**
 * Logout a user
 */
const logoutUser = async () => {
  console.log("loggin out");

  useStore.setState({
    user: undefined,
    completed: undefined,
    evaluation: undefined,
    textInputs: undefined,
    currentPagePath: undefined,
    currentSlugRoot: undefined,
    pageContext: undefined,
    socialPollAnswer: undefined,
    globalQuiz: undefined,
    globalQuizOutcomes: undefined,
    isGroupAdmin: undefined,
    engagement: undefined,
  });
  auth
    .signOut()
    .then(() => {
      // Sign-out successful.
      console.log("succesfully logged out");
      navigate("/");
    })
    .catch(error => {
      // An error happened.
      console.log({ error });
    });
};

const checkCookie = async () => {
  const isProd = Boolean(process.env.NODE_ENV === "production");

  useTabVisibility(() => {
    console.log("isVisible");
    if (isProd == false) return;
    const loginCookieExists = getCookieValue("101LaunchpadLogin");
    console.log(loginCookieExists);
    if (loginCookieExists == false) {
      console.log("logout");
      setTimeout(() => {
        logoutUser();
      }, 1000);
    }
  });
};

const handleResetPassword = async (email: string) => {
  if (email == null) return;

  const reset = await sendPasswordResetEmail(auth, email);
  console.log({ reset });

  return reset;
};

export {
  initFirebase,
  auth,
  onAuthStateChanged,
  database,
  handleGoogleAuth,
  handleFacebookAuth,
  setUserToDatabase,
  handleCreateAccountWithEmail,
  handleLoginWithEmail,
  logoutUser,
  checkCookie,
  handleResetPassword,
  handleCheckActivationCode,
  handleCheckGroupAdmin,
};
