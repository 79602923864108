import {
  CompletedPages,
  EngagementProps,
  GlobalQuiz,
  GlobalQuizQuestion,
  User,
  WorkshopContext,
} from "./types";
import { Maybe, SanitySection } from "@graphql-types";
import { devtools, persist } from "zustand/middleware";
import {
  useAddFortuneToQuiz,
  useAddGlobalQuizAnswer,
  useAddGoal,
  useAddTextInput,
  useClearQuiz,
  useSaveEngagement,
  useSaveEvaluation,
  useUpdateCompletedPage,
} from "./logicHooks";

import { PageContext } from "@util/types";
import create from "zustand";
import { getCookieValue, setCookie } from "@util/helper";

interface State {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
  user?: User;
  setUser: (user?: User) => void;

  //Quizz
  globalQuiz: GlobalQuiz[];
  setGlobalQuizz: (globalQuiz: GlobalQuiz[]) => void;
  setGlobalQuizzAnswer: (question: any, ref: Maybe<string> | undefined) => void;
  setGlobalQuizzFortuneCard: (fortuneCard: any, ref: Maybe<string> | undefined) => void;
  clearGlobalQuizz: (ref: Maybe<string> | undefined) => void;
  globalQuizOutcomes?: any[];
  setGlobalQuizOutcomes: (globalQuizOutcomes: any[]) => void;

  //preview
  isPreview: boolean;
  setIsPreview: (isPreview: boolean) => void;

  //Popup visibility
  globalPopupVisibility: boolean;
  setGlobalPopupVisibility: (globalPopupVisibility: boolean) => void;

  //Text inputs
  textInputs: any[];
  setTextInputs: (textInput: string, id: string) => void;

  // Social poll
  socialPollAnswer?: any;
  setSocialPollAnswer: (doc: any) => void;

  // Personality quiz
  personalityQuiz?: any;
  setPersonalityQuiz?: (answer: boolean, personalityType: string) => void;

  //Goals
  goals: any[];
  setGoals?: (
    goalGroup: Maybe<string> | undefined,
    fieldType: Maybe<string> | undefined,
    text: Maybe<string> | undefined,
  ) => void;

  //user
  userName?: string;
  setUserName?: (userName: string) => void;

  //Config
  pageContext?: PageContext;
  setPageContext: (pageContext: PageContext) => void;

  completed?: CompletedPages[];
  setCompleted: (completed: any) => void;
  engagement?: EngagementProps;
  setEngagement: (engagement: EngagementProps) => void;

  currentSlugRoot?: string;
  setCurrentSlugRoot: (currentSlugRoot: string) => void;
  currentPagePath?: string;
  setCurrentPagePath: (currentPagePath: string) => void;

  //Evaluation page
  evaluation?: any[];
  setEvaluation: (evaluation: any, evaluationTitle: string) => void;

  //Mentor popups
  globalMentorPopups?: string[];
  setGlobalMentorPopups?: (mentorPopups: string) => void;
  mentor?: {
    id: Maybe<string> | undefined;
    isMuted?: boolean | undefined;
  };
  setMentor?: (mentor: { id: Maybe<string> | undefined; isMuted?: boolean | undefined }) => void;

  //popups visible
  popupVisible?: { visible: boolean; id: string };
  setPopupVisible?: (visible: boolean, id: string) => void;

  isGroupAdmin?: boolean | undefined;
  setIsGroupAdmin?: (isGroupAdmin: boolean) => void;

  overrideBadgeName?: string | null;
  setOverrideBadgeName: (overrideBadgeName: string | null) => void;

  workshopTabsTourState?: number;
  setWorkshopTabsTourState: (workshopTabsTourState: number) => void;
}

const defaultState = {
  loggedIn: false,
  globalPopupVisibility: false,
  globalQuiz: [],
  isPreview: false,
  textInputs: [],
  goals: [],
  evaluation: [],
  globalMentorPopups: [],
  isGroupAdmin: undefined,
  mentor: {
    id: undefined,
    isMuted: false,
  },
  engagement: [],
  workshopTabsTourState: 0,
};

const devtoolsInNonProd =
  process.env.GATSBY_NODE_ENV === "production" ? (store: any) => store : devtools;

export const useStore = create<State>(
  devtoolsInNonProd(
    persist<State>(
      (set, get) => ({
        ...defaultState,
        setLoggedIn: loggedIn => set({ loggedIn }),
        setGlobalPopupVisibility: globalPopupVisibility => set({ globalPopupVisibility }),
        setGlobalQuizz: globalQuiz => {
          const currentGlobalQuiz = get().globalQuiz ?? [];
          set({ globalQuiz: [...currentGlobalQuiz, ...globalQuiz] });
        },
        setIsPreview: isPreview => set({ isPreview }),
        setGlobalQuizzFortuneCard: (fortuneCard: any, ref: Maybe<string> | undefined) => {
          if (ref == null) return;
          const globalQuizArray = get().globalQuiz;
          const updatedQuizArray = useAddFortuneToQuiz(ref, fortuneCard, globalQuizArray);
          if (updatedQuizArray == null) return;
          set({ globalQuiz: updatedQuizArray });
          return "added";
        },
        setGlobalQuizzAnswer: (question: GlobalQuizQuestion, ref: Maybe<string> | undefined) => {
          if (ref == null) return;
          const globalQuizArray = get().globalQuiz;
          const updatedQuizArray = useAddGlobalQuizAnswer(ref, question, globalQuizArray);
          if (updatedQuizArray == null) return;
          set({ globalQuiz: updatedQuizArray });
          //total score
        },
        clearGlobalQuizz: (ref: Maybe<string> | undefined) => {
          if (ref == null) return;
          const globalQuizArray = get().globalQuiz;
          const resetQuiz = useClearQuiz(ref, globalQuizArray);
          if (resetQuiz == null) return;
          set({ globalQuiz: resetQuiz });
        },
        setGlobalQuizOutcomes: globalQuizOutcomes => set({ globalQuizOutcomes }),
        setTextInputs: (textInput: string, id: string) => {
          if (textInput == null) return;
          const userID = get().user?.uid;

          const updatedTextInputArray = useAddTextInput(get().textInputs, textInput, id, userID);

          set({ textInputs: updatedTextInputArray });
        },
        setSocialPollAnswer: (doc: any) => {
          if (doc == null) return;
          set({ socialPollAnswer: doc });
        },
        setPersonalityQuiz: (answer: boolean, personalityType: string) => {
          const quiz = get().personalityQuiz;
          if (answer) {
            const res = quiz.personalityType;
          }
        },
        setGoals: (
          goalGroup: Maybe<string> | undefined,
          fieldType: Maybe<string> | undefined,
          text: Maybe<string> | undefined,
        ) => {
          if (goalGroup == null || fieldType == null || text == null) return;
          const goals = get().goals;
          const userID = get().user?.uid;
          const updatedArray = useAddGoal(goals, goalGroup, fieldType, text, userID);
          if (updatedArray) {
            set({ goals: updatedArray });
          }
        },
        setUserName: (userName: string) => {
          if (userName == null) return;
          set({ userName: userName });
        },
        setUser: user => set({ user }),
        setCurrentSlugRoot: currentSlugRoot => set({ currentSlugRoot }),
        setCurrentPagePath: currentPagePath => set({ currentPagePath }),
        setPageContext: pageContext => set({ pageContext }),
        setEvaluation: (evaluation: any, evaluationTitle: string) => {
          if (evaluation == null) return;
          const workshop = get().pageContext?.workshopContext?.workshop;
          const user = get().user?.uid;

          // set({ evaluation: evaluation });
          useSaveEvaluation(workshop, evaluation, user, evaluationTitle ?? "Evaluation");
        },
        setCompleted: (completed: any) => {
          const cookie = getCookieValue("101LaunchpadLogin");
          if (cookie) {
            if (completed == null) return;
            if (completed.page_id == null || completed.page_id == undefined) return;
            const currentPageContext = get().pageContext;
            const currentlyCompleted = get().completed;
            const userID = get().user?.uid;
            const currentEngagement = get().engagement;
            const updatedCompletedWorkshops = useUpdateCompletedPage(
              completed,
              currentlyCompleted,
              userID,
              currentPageContext,
              currentEngagement,
            );
            if (updatedCompletedWorkshops) {
              //get current page context

              //Add cookie
              setCookie("101LaunchpadLogin", "true", 1);
              set({ completed: updatedCompletedWorkshops });
            }
          } else {
            console.log("no cookie");

            // logoutUser();
          }
        },
        setEngagement: (engagement: EngagementProps) => {
          const cookie = getCookieValue("101LaunchpadLogin");
          console.log({ engagement });

          if (cookie) {
            const currentEngagement = get().engagement;
            const updatedEngagement = useSaveEngagement(currentEngagement, engagement);

            console.log({ updatedEngagement });

            if (updatedEngagement) {
              set({ engagement: updatedEngagement });

              const currentUser = get().user;
              set({ user: { ...currentUser, engagement: updatedEngagement } });
            }
          }
        },
        setGlobalMentorPopups: (mentorPopups: string) => {
          if (mentorPopups == null) return;
          const mentorPopupsArray = get().globalMentorPopups;
          const updatedMentorPopupsArray = [...mentorPopupsArray, mentorPopups];
          set({ globalMentorPopups: updatedMentorPopupsArray });
        },
        setPopupVisible: (visible: boolean, id: string) => {
          if (visible == null || id == null) return;
          const updatedPopupVisible = { visible: visible, id: id };
          set({ popupVisible: updatedPopupVisible });
        },
        setMentor: (mentor: { id: Maybe<string> | undefined; isMuted?: boolean | undefined }) => {
          if (mentor == null) return;
          set({ mentor: mentor });
        },
        setIsGroupAdmin: (isGroupAdmin: boolean) => {
          if (isGroupAdmin == null) return;
          set({ isGroupAdmin: isGroupAdmin });
        },
        setOverrideBadgeName: (overrideBadgeName: string) => {
          if (overrideBadgeName == null) return;
          set({ overrideBadgeName: overrideBadgeName });
        },
        setWorkshopTabsTourState: (workshopTabsTourState: number) => {
          if (workshopTabsTourState == null) return;
          set({ workshopTabsTourState: workshopTabsTourState });
        },
      }),
      {
        name: "life101Oz",
        getStorage: () => localStorage,
        partialize: state =>
          Object.fromEntries(
            Object.entries(state).filter(
              ([key]) => !["popupVisible", "workshopTabsTourState"].includes(key),
            ),
          ),
      },
    ),
  ),
);
