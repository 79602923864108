import "./static/fonts/fonts.css";
import "firebase/auth";
import "firebase/firestore";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import AuthProvider from "./src/auth/authContext";
import Layout from "./src/shared/layout";
import React from "react";

/*
 * Custom fonts
 * Uncomment below if using custom fonts
 */
// import "./static/fonts/fonts.css";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
}

export function wrapPageElement({ element, props }) {
  return (
    <AuthProvider>
      <Layout {...props}>{element}</Layout>
    </AuthProvider>
  );
}
