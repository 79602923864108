import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { User, onAuthStateChanged } from "firebase/auth";

import { initFirebase } from "./getFirebase";
import { usePageLoaded } from "@util/hooks";
import { useStore } from "@state/store";
import { timeStamp } from "console";

interface Props {
  children: ReactNode;
}

interface Context {
  authUser: User | undefined;
  firebase: any | undefined;
}

export const AuthContext = createContext<Context>({
  authUser: undefined,
  firebase: undefined,
});

const AuthProvider = ({ children }: Props) => {
  const storeUser = useStore().user;
  const shouldLoad = usePageLoaded();
  const [authUser, setAuthUser] = useState(storeUser);
  const [firebase, setFirebase] = useState<any | undefined>(undefined);

  useEffect(() => {
    if (!shouldLoad) return;
    let unsubscribe;

    const asyncInitFirebase = async () => {
      const firebaseInit = await initFirebase();

      if (firebaseInit == null) return;
      setFirebase(firebaseInit);
      unsubscribe = onAuthStateChanged(firebaseInit.auth, user => {
        if (user) {
          setAuthUser(user);
        } else {
          setAuthUser(undefined);
        }
      });
    };

    try {
      asyncInitFirebase();
    } catch (error) {
      console.log("initFirebase error: ", error);
    }

    return unsubscribe;
  }, [shouldLoad]);

  return <AuthContext.Provider value={{ authUser, firebase }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
