import {
  FONT_BODY,
  FONT_BODY_WEIGHT,
  FONT_HEADER,
  FONT_HEADER_WEIGHT,
  PRIMARY_COLOR,
  fontSizes,
  fontWeights,
} from "@util/constants";
import { MOBILE_BREAKPOINT, mediaQuery } from "@util/constants";
import styled, { createGlobalStyle } from "styled-components";

import { em } from "@util/helper";

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0px;
    font-family: ${FONT_BODY}, sans-serif;
    font-size: ${fontSizes.p.default}px;
    font-weight: ${FONT_BODY_WEIGHT};
    color: ${PRIMARY_COLOR};
    line-height: 1.4;
    scroll-behavior: smooth;


    -webkit-user-select: text;
-khtml-user-select: text;
-moz-user-select: text;
-ms-user-select: text;
-o-user-select: text;
user-select: text;


  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  body,
  input,
  textarea,
  button,
  select {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }

  em {
    font-style: italic;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  img {
    max-width: 100%;
    border: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  button,
  html input {
    &[disabled] {
      cursor: default;
    }
  }

  input {
    &[type="search"],
    &[type="number"],
    &[type="email"],
    &[type="password"] {
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  textarea {
    overflow: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  h0,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${FONT_HEADER}, sans-serif;
    font-weight: ${FONT_HEADER_WEIGHT};
    line-height: 1.2;
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    a {
      color: inherit;
      text-decoration: none;
      font-weight: inherit;
    }
  }

  form {
    z-index: 100;
  }

  .small, small {
    font-size: ${em(fontSizes.small.default)};
    line-height: 20px;
  }

  h0, .h0{
    font-size: ${em(fontSizes.h0.default)};
    font-weight: ${fontWeights.bold};
  }

  h1, .h1{
    font-size: ${em(fontSizes.h1.default)};
    font-weight: ${fontWeights.bold};
  }

  h2, .h2{
    font-size: ${em(fontSizes.h2.default)};
  }

  h3, .h3{
    font-size: ${em(fontSizes.h3.default)};
    font-weight: ${fontWeights.semibold};
  }

  h4, .h4{
    font-size: ${em(fontSizes.h4.default)};
  }

  h5, .h5{
    font-size: ${em(fontSizes.h5.default)};
  }

  ${mediaQuery.tabletDown} {
    h0, .h0{
      font-size: ${em(fontSizes.h0.mobile)};
      font-weight: ${fontWeights.bold};
    }

    .ReactModal__Content {
      width: 95% !important;
      padding: 0 !important;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s ease all;

    &:visited {
      color: currentColor;
    }

  }

  .swiper-button-disabled {
    display: none;
  }


  .ReactModal__Content {
    -ms-overflow-style: none; 
    scrollbar-width: none;     
  }

  .ReactModal__Content::-webkit-scrollbar {
    display: none;
  }

 .print-body div.gatsby-image-wrapper {
  width: 100% !important;

   .gatsby-image-wrapper {
     width: 100% !important;
    } 
 }

 .react-joyride__overlay{
  z-index: 200 !important;
 }

`;

export const Main = styled.main<{}>`
  // max-width: 1920px;
  margin: auto;
`;
