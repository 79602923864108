exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kitchen-sink-tsx": () => import("./../../../src/pages/kitchen-sink.tsx" /* webpackChunkName: "component---src-pages-kitchen-sink-tsx" */),
  "component---src-pages-personality-quiz-tsx": () => import("./../../../src/pages/personality-quiz.tsx" /* webpackChunkName: "component---src-pages-personality-quiz-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-dashboard-template-tsx": () => import("./../../../src/templates/dashboardTemplate.tsx" /* webpackChunkName: "component---src-templates-dashboard-template-tsx" */),
  "component---src-templates-group-dashboard-template-tsx": () => import("./../../../src/templates/groupDashboardTemplate.tsx" /* webpackChunkName: "component---src-templates-group-dashboard-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/pageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-section-template-tsx": () => import("./../../../src/templates/sectionTemplate.tsx" /* webpackChunkName: "component---src-templates-section-template-tsx" */),
  "component---src-templates-text-chat-template-tsx": () => import("./../../../src/templates/textChatTemplate.tsx" /* webpackChunkName: "component---src-templates-text-chat-template-tsx" */),
  "component---src-templates-workshop-template-tsx": () => import("./../../../src/templates/workshopTemplate.tsx" /* webpackChunkName: "component---src-templates-workshop-template-tsx" */)
}

