import {
  DocumentData,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  where,
  collection,
  query,
  getDocs,
  addDoc,
  runTransaction,
} from "firebase/firestore";
import { OnRequestStatus, RequestStatus } from "@util/types";
import { useEffect, useState } from "react";

import { Maybe } from "@graphql-types";
import { database } from "@auth/getFirebase";

import { Group, User } from "@state/types";

/**
 * Firestore functions and  status
 * @param collection collection name
 * @param docId
 * @param data data to set and update
 * @returns
 */
export function useFirestoreDocFuncs(
  collection: string,
  docId: Maybe<string> | undefined,
  data: any,
  onRequestStatus: OnRequestStatus,
) {
  const [status, setStatus] = useState<RequestStatus>("idle");
  const [updatedData, setUpdatedData] = useState<DocumentData | undefined>();

  useEffect(() => {
    console.log({ database });
  }, [database]);

  const docRef = docId && database ? doc(database, collection, docId) : undefined;
  const { success: onSuccess, failed: onFailed, pending: onPending } = onRequestStatus;

  useEffect(() => {
    if (status === "idle") return;
    if (status === "pending" && onPending) onPending();
    if (status === "success" && onSuccess) onSuccess();
    if (status === "failed" && onFailed) onFailed();
  }, [status]);

  /**
   *  Update a document with data object
   */
  const set = async () => {
    if (docRef == null) return;
    setStatus("pending");
    try {
      await setDoc(docRef, data, { merge: true });
      setStatus("success");
    } catch (e) {
      console.error("Error setting doc: ", e);
      setStatus("failed");
    }
  };

  /**
   *  Get the document
   */
  const get = async () => {
    console.log({ docRef });

    if (docRef == null) {
      setUpdatedData(undefined);
      setStatus("failed");
      return;
    }
    setStatus("pending");
    try {
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        setStatus("success");
        console.log("Document data:", docSnapshot.data());

        setUpdatedData(docSnapshot.data());
        return;
      }

      setUpdatedData(undefined);
      setStatus("failed");
    } catch (e) {
      console.error("Error setting doc: ", e);
      setStatus("failed");
      setUpdatedData(undefined);
    }
  };

  /**
   * Update a document field
   */
  const update = async () => {
    if (docRef == null) return;
    setStatus("pending");
    try {
      await updateDoc(docRef, data);
      await get();
      setStatus("success");
    } catch (e) {
      console.error("Error updating doc: ", e);
      setStatus("failed");
    }
  };

  /** Set a doc if get is undefined */
  const getAndSet = async () => {
    console.log({ docRef });

    if (docRef == null) return;
    setStatus("pending");
    try {
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        setStatus("success");
        console.log("Document data:", docSnapshot.data());
        setUpdatedData(docSnapshot.data());
        return;
      }

      await set();
      setStatus("success");
    } catch (e) {
      console.error("Error setting doc: ", e);
      setStatus("failed");
      setUpdatedData(undefined);
    }
  };

  return { status, set, get, update, getAndSet, updatedData };
}

export async function useFirstoreSaveSocialPoll(
  collection: string,
  docId: Maybe<string> | undefined,
  data: any,
) {
  const docRef = docId && database ? doc(database, collection, docId) : undefined;
  if (docRef == null) return;
  try {
    await updateDoc(docRef, data);
  } catch (e) {
    console.error("Error updating doc: ", e);
  }
}

export async function useFirebaseGetSocialPoll(
  collection: string,
  docId: Maybe<string> | undefined,
) {
  const docRef = docId && database ? doc(database, collection, docId) : undefined;
  if (docRef == null) return;
  try {
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      return docSnapshot.data();
    }
  } catch (e) {
    console.error("Error updating doc: ", e);
    return null;
  }

  return;
}

export async function useFirebaseUpdateUser(
  collection: string,
  docId: Maybe<string> | undefined,
  data: any,
) {
  const docRef = docId && database ? doc(database, collection, docId) : undefined;
  console.log({ docRef });

  if (docRef == null) return;
  try {
    const update = await updateDoc(docRef, data);
    console.log({ update });

    return "success";
  } catch (e) {
    console.error("Error updating doc: ", e);
    return null;
  }
}

export async function useFirebaseUpdateEvaluation(
  collection: string,
  docId: Maybe<string> | undefined,
  data: any,
) {
  const docRef = docId && database ? doc(database, collection, docId) : undefined;
  if (docRef == null) return;
  try {
    await updateDoc(docRef, data);
    return data;
  } catch (e) {
    console.error("Error updating doc: ", e);
    try {
      await setDoc(docRef, data);
      return data;
    } catch (error) {
      console.error("Error updating doc: ", e);
      return null;
    }
    return null;
  }
}

export async function userFirebaseGetGroup(collection: string, docId: Maybe<string> | undefined) {
  const docRef = docId && database ? doc(database, collection, docId) : undefined;
  if (docRef == null) return;
  try {
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      return docSnapshot.data();
    }
  } catch (e) {
    console.error("Error updating doc: ", e);
    return null;
  }

  return null;
}

export async function useFirebaseGroupLookup(email: string | null | undefined) {
  console.log({ email });

  if (email == null) return null;

  // Ensure database is initialized
  if (!database) {
    console.log("Database is not initialized.");
    return [];
  }

  try {
    const adminQuery = query(collection(database, "groups"), where("admin", "==", email));
    const membersQuery = query(
      collection(database, "groups"),
      where("groupMembers", "array-contains", email),
    );

    console.log({ adminQuery, membersQuery });

    const adminGroups: Group[] = [];

    if (adminQuery) {
      const adminSnapshot = await getDocs(adminQuery);
      adminSnapshot.docs.forEach(doc => {
        adminGroups.push({ ...doc.data(), usersLoaded: false });
      });
    }

    if (membersQuery) {
      const membersSnapshot = await getDocs(membersQuery);
      membersSnapshot.docs.forEach(doc => {
        adminGroups.push({ ...doc.data(), usersLoaded: false });
      });
    }

    return adminGroups;
  } catch (error) {
    console.error("Failed to fetch group data:", error);
    return [];
  }
}

export async function userFirebaseGetGroupUser(docId: string) {
  const docRef = docId && database ? doc(database, "users", docId) : undefined;
  if (docRef == null) return;
  try {
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const createdDate = docSnapshot?._document?.createTime?.timestamp;
      const date = convertTimestamp(createdDate.seconds, createdDate.nanoseconds);

      return {
        ...docSnapshot.data(),
        uid: docId,
        createdDate: date,
      };
    }
  } catch (e) {
    console.error("Error updating doc: ", e);
    return null;
  }

  return null;
}

export function convertTimestamp(seconds: number, nanoseconds: number): Date {
  const milliseconds = seconds * 1000 + nanoseconds / 1_000_000;
  console.log({ milliseconds });

  return new Date(milliseconds);
}

/*
 *
 * Admin hooks
 */
export async function useFirebaseGetAllGroups() {
  console.log("here");

  const groupsQuery = database ? query(collection(database, "groups")) : undefined;
  let groups: Group[] = [];

  try {
    if (groupsQuery) {
      const groupsSnapshot = await getDocs(groupsQuery);

      for (const doc of groupsSnapshot.docs) {
        let groupData = doc.data();
        let groupId = doc.id;

        // Query license_keys collection with the current groupId
        const licenseKeyQuery = query(
          collection(database, "license_keys"),
          where("group", "==", groupId),
        );

        const licenseKeySnapshot = await getDocs(licenseKeyQuery);
        let licenseKeys = licenseKeySnapshot.docs.map(doc => doc.data());

        groups.push({ ...groupData, usersLoaded: false, licenseKey: licenseKeys[0] });
      }
    }

    console.log(groups);

    return groups;
  } catch (error) {
    console.error("Error fetching groups:", error);
    return error;
  }
}

export async function useFirebaseCreateGroup(group: any) {
  if (!database) {
    return { error: "Database is not initialized" };
  }

  if (!group || typeof group !== "object") {
    return { error: "Invalid group data" };
  }

  const groupRef = collection(database, "groups");
  const keysRef = collection(database, "license_keys");

  let newGroupRef;
  let newKeyRef;

  try {
    await runTransaction(database, async transaction => {
      newGroupRef = doc(groupRef);
      transaction.set(newGroupRef, group);

      console.log("New group ID: ", newGroupRef.id);

      let key = Math.random().toString(36).substring(7);
      const newKey = {
        key: key,
        assigned: 0,
        usesAvailable: group.seats ?? 1,
        email: group.admin,
        group: newGroupRef.id,
      };

      newKeyRef = doc(keysRef);
      transaction.set(newKeyRef, newKey);
    });
  } catch (error) {
    console.error("Error in transaction:", error);
    return { error: "Error creating group and license key" };
  }

  return { success: "Group successfully created", groupId: newGroupRef?.id ?? "" };
}

export async function useCheckUserIsGroupAdmin(email: String) {
  const adminQuery = database
    ? query(collection(database, "groups"), where("admin", "==", email))
    : undefined;
  console.log({ adminQuery });

  if (adminQuery) {
    const adminSnapshot = await getDocs(adminQuery);
    if (adminSnapshot.docs.length > 0) {
      return true;
    }
    try {
      return false;
    } catch (error) {
      console.log({ error });

      return false;
    }
  }
  return false;
}

export function useFirebaseGetEvaluations(user: string) {
  const [userEvaluations, setUserEvaluations] = useState([]);

  useEffect(() => {
    if (!user || userEvaluations.length > 0) return;

    const fetchEvaluations = async () => {
      const evaluationsRef = query(collection(database, "users", user, "evaluations"));
      const evaluationsSnapshot = await getDocs(evaluationsRef);

      const evaluations = evaluationsSnapshot.docs
        .map(doc => {
          const evaluation = doc.data();
          evaluation.id = doc.id;
          return evaluation;
        })
        .filter(evaluation => evaluation != null);

      setUserEvaluations(evaluations);
    };

    fetchEvaluations();
  }, [user]); // Empty dependency array to run only once on mount

  return { userEvaluations };
}

export async function useFirebaseLegacyEvaluationsLookup(
  userID: string | null | undefined,
  workshop: string,
) {
  if (userID == null) return null;

  // Ensure database is initialized
  if (!database) {
    console.log("Database is not initialized.");
    return [];
  }

  try {
    const evaluations = query(collection(database, "evaluations"), where("user.uid", "==", userID));

    const adminGroups: Group[] = [];

    if (evaluations) {
      const adminSnapshot = await getDocs(evaluations);
      adminSnapshot.docs.forEach(doc => {
        adminGroups.push({ ...doc.data(), usersLoaded: false });
      });
    }

    return adminGroups;
  } catch (error) {
    console.error("Failed to fetch group data:", error);
    return [];
  }
}
